import React, { memo } from 'react';

import { Button, Alert } from '../../../components';
import {
  ButtonContainer,
  Heading1,
  Instruction,
  InstructionWrapper,
  VerificationLink,
} from '../../../layout/user-layout';
import { MESSAGE_STATUS } from '../../../constants';

type VerificationProps = {
  onButtonClick?: () => void;
  headingText: string;
  paragraphText?: string;
  buttonText?: string;
  status?: string;
  linkText?: string;
  onLinkClick?: () => void;
};

const EmailVerificationConfirmation = memo(function EmailVerificationConfirmation({
  onButtonClick,
  headingText,
  paragraphText,
  buttonText,
  status,
  linkText,
  onLinkClick,
}: VerificationProps) {
  const renderInstruction = () => {
    if (paragraphText) {
      return <Instruction>{paragraphText}</Instruction>;
    }
  };

  const renderAlert = () => {
    if (status === MESSAGE_STATUS.SUCCESS) {
      return <Alert message="An email has been sent to the associated email address" type="success" closable />;
    } else if (status === MESSAGE_STATUS.ERROR) {
      return <Alert message="Email cannot be sent" type="error" />;
    } else {
      return null;
    }
  };

  const renderButton = () => {
    if (buttonText) {
      return (
        <Button type="primary-blue" htmlType="submit" onClick={onButtonClick}>
          {buttonText}
        </Button>
      );
    } else {
      return null;
    }
  };

  const renderLink = () => {
    if (linkText) {
      return <VerificationLink onClick={onLinkClick}>{linkText}</VerificationLink>;
    } else {
      return null;
    }
  };

  return (
    <div>
      {renderAlert()}
      <InstructionWrapper>
        <Heading1>{headingText}</Heading1>
        {renderInstruction()}
        <ButtonContainer>{renderButton()}</ButtonContainer>
        {renderLink()}
      </InstructionWrapper>
    </div>
  );
});

export default EmailVerificationConfirmation;
